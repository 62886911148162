import type { FC } from "react";

interface DownArrowIconProps {
  width: number;
  height: number;
  color: string;
  className?: string;
}

const DownArrowIcon: FC<DownArrowIconProps> = ({
  width,
  height,
  color,
  className = "",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 12"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8705 0.5L9.29027 8.25122L1.59584 0.671288L0 2.32392L9.34706 11.5L18.5231 2.09615L16.8705 0.5Z"
      fill={color}
    />
  </svg>
);

export default DownArrowIcon;
