export async function updateActiveHeroIndex(activeHeroIndex: number) {
  try {
    let response = await fetch("/api/users/active-hero", {
      method: "PATCH",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ activeHeroIndex }),
    });

    if (response.ok) {
      window.location.reload();
    }
  } catch (error) {
    console.log(error);
  }
}
