import type { FC } from "react";

interface CloseButtonProps {
  onClick: (event: any) => any;
  color?: string;
  className?: string;
}

const CloseButton: FC<CloseButtonProps> = ({
  onClick,
  color = "#e5e5e5",
  className = "",
}) => (
  <button aria-label="Close" onClick={onClick} className={className}>
    <svg
      aria-hidden
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="-0.777778"
        x2="38.081"
        y2="-0.777778"
        transform="matrix(0.735226 -0.677822 0.735226 0.677822 2.00244 27.812)"
        stroke={color}
        strokeWidth="1.55556"
      />
      <line
        y1="-0.777778"
        x2="38.081"
        y2="-0.777778"
        transform="matrix(-0.735226 -0.677822 0.735226 -0.677822 29.998 28.1958)"
        stroke={color}
        strokeWidth="1.55556"
      />
    </svg>
  </button>
);

export default CloseButton;
