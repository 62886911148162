import type { FC } from "react";

interface HeadingProps {
  text: string;
  size?: "small" | "large" | "xl";
  uppercase?: boolean;
  className?: string;
}

const Heading: FC<HeadingProps> = ({
  text,
  size = "",
  uppercase = true,
  className = "",
}) => (
  <h2
    className={`
      font-medium
      ${size === "" && "text-2xl tracking-wider"}
      ${size === "small" && "font-bold tracking-wider"}
      ${size === "large" && "text-3xl md:text-4xl tracking-wider"}
      ${size === "xl" && "text-6xl"}
      ${uppercase && "uppercase"}
      ${className}
    `}
  >
    {text}
  </h2>
);

export default Heading;
