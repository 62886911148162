import type { FC } from "react";
import Image from "next/image";

import smallBrushSvg from "public/brush_small.svg";

export const ComingSoonBadge: FC = () => (
  <span
    className="
      text-center text-white text-xs font-heading font-medium
      tracking-wider uppercase
      block relative
    "
  >
    <span
      className="
        w-[79px] h-[29px]
        absolute top-1/2 left-1/2
        translate-x-[-48%] translate-y-[-60%]
      "
    >
      <Image src={smallBrushSvg} alt="" />
    </span>

    <span className="relative z-10">Coming Soon</span>
  </span>
);
