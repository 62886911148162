import { Hero } from "@prisma/client";
import Container from "components/Container";
import HamburgerButton from "components/HamburgerButton";
import Logo from "components/Logo";
import DownArrowIcon from "components/svgs/DownArrowIcon";
import useOnOutsideClick from "hooks/outside-click";
import { isFlagOn } from "lib/feature-flags";
import type { Session } from "next-auth";
import Image from "next/image";
import Link from "next/link";
import type { FC } from "react";
import { useRef, useState } from "react";
import { ComingSoonBadge } from "./ComingSoonBadge";
import HeroSwitcher from "./HeroSwitcher";
import { MobileNav } from "./MobileNav";
import { NavDropdown } from "./NavDropdown";
import { NavLink } from "./NavLink";

export let navItemsSubquery = `
  navItems {
    id
    text
    path
    comingSoon
    featureFlags {
      id
      activeOnPreview
      activeOnProduction
    }
  }
`;

export let portalNavItemsSubquery = `
  portalNavItems {
    text
    path
    featureFlags {
      id
      activeOnPreview
      activeOnProduction
    }
  }
`;

interface NavItemFeatureFlag {
  id: string;
  activeOnPreview: boolean;
  activeOnProduction: boolean;
}

export type NavItems = {
  id: string;
  text: string;
  path: string;
  comingSoon: boolean;
  featureFlags?: NavItemFeatureFlag[];
}[];

interface NavProps {
  items: NavItems;
  session?: Session | null;
  transparent?: boolean;
}

const Nav: FC<NavProps> = ({ items, transparent = false, session }) => {
  const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(false);
  const [navDropdownActive, setNavDropdownActive] = useState<boolean>(false);
  const [switcherActive, setSwitcherActive] = useState<boolean>(false);
  const navContainerRef = useRef(null);
  useOnOutsideClick(navContainerRef, () => setNavDropdownActive(false));
  const renderNavDropdown = session && navDropdownActive;
  const renderSwitcher = session && switcherActive;
  const activeHero = session && (session.activeHero as Hero);
  const validateNavItemFeatureFlags = (
    flags: NavItemFeatureFlag[] | undefined
  ): boolean => {
    if (!flags || !flags?.length) {
      return true;
    }

    const isAllFlagsOn = flags.every((flag) =>
      isFlagOn(flag.activeOnPreview, flag.activeOnProduction)
    );

    return isAllFlagsOn;
  };

  return (
    <>
      <nav
        className={`
          ${!transparent && "bg-[#232323]"}
          width-100 relative z-20 md:z-10
        `}
        ref={navContainerRef}
      >
        <Container className="relative py-3 md:py-8 flex justify-between items-center">
          <Link href={activeHero ? "/portal" : "/"}>
            <a>
              <Logo textColor="#f5efe7" className="w-[110px] md:w-auto" />
            </a>
          </Link>

          <HamburgerButton
            onClick={() => setMobileMenuActive(true)}
            color="white"
            className="absolute top-8 md:hidden right-5 sm:right-10 active:opacity-50"
          />

          <ul className="hidden md:flex items-center gap-9">
            {items
              .filter((item) => validateNavItemFeatureFlags(item?.featureFlags))
              .map((item, index) => (
                <li key={`nav-item-${index}`} className="relative">
                  <NavLink
                    href={!item.comingSoon ? item.path : ""}
                    text={item.text}
                  />
                  {item.comingSoon && (
                    <span className="w-[79px] absolute top-[-50%] left-1/2 translate-x-[-50%]">
                      <ComingSoonBadge />
                    </span>
                  )}
                </li>
              ))}

            {session && (
              <li>
                <button
                  onClick={() => setNavDropdownActive(!navDropdownActive)}
                  className={`
                    flex flex-row items-center gap-2
                    text-white font-heading font-bold
                    uppercase tracking-wider
                    ${navDropdownActive ? "opacity-100" : "opacity-50"}
                    hover:opacity-100 focus:opacity-100
                  `}
                >
                  {activeHero && (
                    <Image
                      src={(activeHero.metadata as any).image}
                      width={42}
                      height={42}
                      alt=""
                      className="rounded-full"
                    />
                  )}

                  <span className="w-2 h-2 rounded-full bg-green -translate-y-0.5" />

                  {(session as any).walletAddress.substring(0, 6)}
                  {`...`}
                  {(session as any).walletAddress.substring(
                    (session as any).walletAddress.length - 3
                  )}

                  <span
                    className={`
                      transition-all
                      ${navDropdownActive ? "rotate-180" : ""}
                    `}
                  >
                    <DownArrowIcon width={15} height={10} color="white" />
                  </span>
                </button>
              </li>
            )}
          </ul>

          {renderNavDropdown && (
            <NavDropdown
              openSwitcher={() => setSwitcherActive(true)}
              className={!transparent ? "bg-[#232323]" : "bg-black/60"}
              session={session}
            />
          )}
        </Container>
      </nav>

      {mobileMenuActive && (
        <MobileNav
          items={items}
          openSwitcher={() => setSwitcherActive(true)}
          onClose={() => setMobileMenuActive(false)}
          session={session}
        />
      )}

      {renderSwitcher && (
        <HeroSwitcher
          onClose={() => setSwitcherActive(false)}
          session={session}
        />
      )}
    </>
  );
};

export default Nav;
