import type { FC } from "react";
import Image from "next/image";

import brushSvg from "public/brush.svg";

export const MobileComingSoonBadge: FC = () => (
  <div
    className="
      text-xl text-white font-heading font-medium
      tracking-wider uppercase
      block absolute translate-y-[-60%]
    "
  >
    <span
      className="
        w-[221px] h-[60px]
        absolute top-1/2 left-1/2
        translate-x-[-50%] translate-y-[-55%]
      "
    >
      <Image width={386} height={105} src={brushSvg} alt="" />
    </span>

    <span className="relative z-10">Coming Soon</span>
  </div>
);
