import type { FC } from "react";

interface TwitterIconProps {
  width: number;
  height: number;
  color: string;
}

const TwitterIcon: FC<TwitterIconProps> = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.617 6.22369C27.6358 6.49492 27.6358 6.76615 27.6358 7.03988C27.6358 15.3806 21.2862 25 9.67564 25V24.995C6.24586 25 2.88732 24.0176 0 22.1652C0.498719 22.2252 0.999937 22.2552 1.50241 22.2564C4.34473 22.2589 7.1058 21.3052 9.34191 19.5491C6.64083 19.4978 4.27223 17.7367 3.44478 15.1656C4.39097 15.3481 5.36591 15.3106 6.2946 15.0569C3.34979 14.4619 1.23117 11.8746 1.23117 8.86977C1.23117 8.84227 1.23117 8.81602 1.23117 8.78977C2.10862 9.27849 3.09106 9.54973 4.09599 9.57972C1.32242 7.72609 0.46747 4.03632 2.14236 1.15151C5.34716 5.09501 10.0756 7.49236 15.1515 7.74609C14.6428 5.55373 15.3378 3.25637 16.9777 1.71522C19.52 -0.674629 23.5185 -0.552137 25.9084 1.98895C27.322 1.71022 28.6769 1.1915 29.9169 0.456549C29.4456 1.91771 28.4595 3.15888 27.142 3.94758C28.3932 3.80009 29.6156 3.46511 30.7668 2.95389C29.9194 4.22381 28.8519 5.32999 27.617 6.22369Z"
      fill={color}
    />
  </svg>
);

export default TwitterIcon;
