import type { FC } from "react";

interface ContainerProps {
  customWidth?: boolean;
  children: any;
  className?: string;
}

const Container: FC<ContainerProps> = ({
  customWidth = false,
  children,
  className = "",
}) => (
  <div
    className={`
      w-full mx-auto px-5 md:px-10 box-border
      ${!customWidth && "max-w-screen-2xl"}
      ${className}
    `}
  >
    {children}
  </div>
);

export default Container;
