import type { FC } from "react";
import type { Session } from "next-auth";

import { signOut } from "lib/auth";

export interface NavDropdownProps {
  openSwitcher: (args: any) => any;
  className?: string;
  session: Session | null | undefined;
}

export const NavDropdown: FC<NavDropdownProps> = ({
  openSwitcher,
  className = "",
  session,
}) => {
  return (
    <ul
      className={`
        w-48 pb-2 px-5 translate-y-full
        absolute bottom-8 right-5 md:right-10
        flex flex-col
        text-xl text-white
        ${className}
      `}
    >
      {[
        {
          text: "Switch Heroes",
          shouldRender: session && (session.heroes as any).length > 0,
          onClick: openSwitcher,
        },
        {
          text: "Downloads",
          shouldRender: session && (session.heroes as any).length > 0,
          href: "/downloads",
        },
        {
          text: "Disconnect",
          shouldRender: true,
          onClick: async () => {
            await signOut();
          },
        },
      ].map(({ text, onClick, href, shouldRender }, index) => {
        if (shouldRender) {
          const itemClasses = `
            block pb-1 pt-4 font-heading text-left
            text-white/70 hover:text-white focus:text-white
          `;

          return (
            <li key={`nav-item-${index}`}>
              {onClick ? (
                <button onClick={onClick} className={itemClasses}>
                  {text}
                </button>
              ) : (
                <a href={href} className={itemClasses}>
                  {text}
                </a>
              )}
            </li>
          );
        }
      })}
    </ul>
  );
};
