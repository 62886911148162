import type { Domos } from "@prisma/client";

export const domosEnumToName = (domos: Domos) => {
  return {
    OQO: "Oqo",
    TNGO: "T'ngo",
    GNASIS: "Gnasis",
  }[domos];
};

export const getActiveDomos = (
  domos: { name: string; image: { url: string } }[],
  activeHero: { domos: string } | null
) => {
  if (activeHero?.domos) {
    return domos.find(
      (d) =>
        d.name.toUpperCase().replace(/[^a-zA-Z ]/g, "") === activeHero?.domos
    );
  } else {
    return null;
  }
};
