export function isFlagOn(
  activeOnPreview: boolean,
  activeOnProduction: boolean
): boolean {
  return (
    process.env.NODE_ENV === "development" ||
    (process.env.NEXT_PUBLIC_PREVIEW && activeOnPreview) ||
    (process.env.NODE_ENV === "production" && activeOnProduction)
  );
}
