import type { FC } from "react";
import Link from "next/link";
import { Session } from "next-auth";

import { signOut } from "lib/auth";

import type { NavItems } from "./Nav";
import Container from "components/Container";
import Logo from "components/Logo";
import TwitterIcon from "components/svgs/TwitterIcon";
import DiscordIcon from "components/svgs/DiscordIcon";
import { MobileNavLink } from "./MobileNavLink";
import { MobileComingSoonBadge } from "./MobileComingSoonBadge";
import CloseButton from "components/CloseButton";

interface MobileNavProps {
  items: NavItems;
  session?: Session | null;
  openSwitcher: (args: any) => any;
  onClose: (event: any) => any;
}
export const MobileNav: FC<MobileNavProps> = ({
  items,
  openSwitcher,
  onClose,
  session,
}) => (
  <nav
    className="
      uppercut-fade
      backdrop-blur-md
      bg-shadow/90 fixed inset-0 z-20 overflow-y-scroll
    "
  >
    <Container className="min-h-full flex flex-col pt-3 pb-5">
      <CloseButton
        onClick={onClose}
        className="absolute top-8 right-5 sm:right-10 active:opacity-50"
      />

      <Link href="/">
        <a>
          <Logo textColor="#f5efe7" className="w-[110px]" />
        </a>
      </Link>

      <ul className="flex flex-col justify-start my-5">
        {session && (
          <li
            className="
              flex justify-center items-center gap-2
              py-5 border-b border-white/30
              font-heading text-2xl text-white text-center tracking-wider
            "
          >
            <span className="w-3 h-3 rounded-full bg-green -translate-y-0.5" />

            {(session as any).walletAddress.substring(0, 6)}
            {`...`}
            {(session as any).walletAddress.substring(
              (session as any).walletAddress.length - 3
            )}
          </li>
        )}
        {items.map((item) => (
          <li
            key={item.id}
            className="relative border-b border-white/30 last-of-type:border-0"
          >
            <MobileNavLink
              href={!item.comingSoon ? item.path : ""}
              text={item.text}
            />
            {item.comingSoon && <MobileComingSoonBadge />}
          </li>
        ))}
        {[
          {
            text: "Switch Heroes",
            onClick: openSwitcher,
            shouldRender: session && (session.heroes as any).length > 0,
          },
          {
            text: "Downloads",
            href: "/downloads",
            shouldRender: session && (session.heroes as any).length > 0,
          },
          {
            text: "Disconnect",
            onClick: async () => await signOut(),
            shouldRender: session,
          },
        ].map(({ text, onClick, href, shouldRender }, index) => {
          if (shouldRender) {
            const itemClasses = `
              w-full block py-5
              font-heading text-2xl text-white text-center tracking-wider
              active:opacity-50
            `;

            return (
              <li
                key={`misc-items-${index}`}
                className="border-b border-white/30 last-of-type:border-0"
              >
                {onClick ? (
                  <button onClick={onClick} className={itemClasses}>
                    {text}
                  </button>
                ) : (
                  <a href={href} className={itemClasses}>
                    {text}
                  </a>
                )}
              </li>
            );
          }
        })}
      </ul>

      <div
        className="
          mt-auto flex gap-5 items-center justify-center text-white
        "
      >
        <a
          href="https://twitter.com/UppercutNFT"
          target="_blank"
          rel="noreferrer"
          className="active:opacity-50"
        >
          <TwitterIcon width={31} height={25} color="currentColor" />
        </a>

        <a
          href="https://discord.gg/mMNmWaQKna"
          target="_blank"
          rel="noreferrer"
          className="active:opacity-50"
        >
          <DiscordIcon width={42} height={42} color="currentColor" />
        </a>
      </div>
    </Container>
  </nav>
);
