import type { FC } from "react";
import Head from "next/head";

export interface OpenGraphProps {
  item: {
    title: string;
    description: string;
    image: { url: string };
  };
}

export let openGraphSubquery = `
  openGraphItem {
    title
    description
    image { url }
  }
`;

const OpenGraph: FC<OpenGraphProps> = ({ item }) => (
  <Head>
    <title>{item.title}</title>
    <meta name="description" content={item.description} />

    <meta property="og:title" content={item.title} />
    <meta property="og:description" content={item.description} />
    <meta property="og:image" content={item.image?.url} />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:image" content={item.image?.url} />
    <meta property="twitter:title" content={item.title} />
    <meta property="twitter:description" content={item.description} />
  </Head>
);

export default OpenGraph;
