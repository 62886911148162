import type { Hero } from "@prisma/client";
import CloseButton from "components/CloseButton";
import Container from "components/Container";
import Heading from "components/Heading";
import { domosEnumToName } from "lib/domos.client";
import { updateActiveHeroIndex } from "lib/users.client";
import type { Session } from "next-auth";
import Image from "next/image";

interface HeroSwitcherProps {
  onClose: (args: any) => any;
  session: Session | null | undefined;
}

const HeroSwitcher = ({ onClose, session }: HeroSwitcherProps) => {
  if (!session) return null;

  return (
    <nav
      className="
        uppercut-fade bg-shadow/90 fixed inset-0 z-40 overflow-y-scroll
      "
    >
      <Container className="min-h-full pt-8 pb-10 pb-5">
        <CloseButton
          onClick={onClose}
          className="absolute top-8 right-5 sm:right-10 active:opacity-50"
        />

        <Heading
          text="Your Heroes"
          size="large"
          uppercase={false}
          className="mb-8 text-white"
        />

        <ul className="grid sm:grid-cols-2 md:grid-cols-4 gap-5">
          {(session.heroes as Hero[]).map(({ id, domos, metadata }, index) => (
            <li key={`hero-switcher-item-${index}`}>
              <button
                className="
                  uppercut-clip bg-sand aspect-square block w-full relative
                  overflow-hidden hover:scale-105 focus:scale-105 duration-300
                "
                onClick={async () => {
                  try {
                    await updateActiveHeroIndex(index);
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <Image
                  src={(metadata as any).image}
                  alt={`Hero ${id}`}
                  layout="fill"
                  className="object-cover"
                  quality={50}
                />

                <span
                  className="
                    absolute inset-0 z-10 flex flex-col justify-end items-start
                    p-5 text-white text-lg
                  "
                >
                  <strong>{`${(metadata as any).name} (#${id})`}</strong>
                  {`Domos ${domosEnumToName(domos)}`}
                </span>
              </button>
            </li>
          ))}
        </ul>
      </Container>
    </nav>
  );
};

export default HeroSwitcher;
