import type { FC } from "react";

interface HamburgerButtonProps {
  onClick: (event: any) => any;
  color?: string;
  className: string;
}

const HamburgerButton: FC<HamburgerButtonProps> = ({
  onClick,
  color = "#e5e5e5",
  className,
}) => (
  <button onClick={onClick} className={className}>
    <svg
      width="30"
      height="22"
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 1H30" stroke={color} strokeWidth="2" />
      <path d="M0 11H30" stroke={color} strokeWidth="2" />
      <path d="M0 21H24" stroke={color} strokeWidth="2" />
    </svg>
    ;
  </button>
);

export default HamburgerButton;
