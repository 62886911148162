import type { FC } from "react";
import Link from "next/link";

export interface NavLinkProps {
  href: string;
  text: string;
}

export const NavLink: FC<NavLinkProps> = ({ href, text }) => {
  let classes = `
    block
    text-white/50 ${href && "hover:text-white focus:text-white"}
    font-heading font-bold uppercase tracking-wider
  `;

  if (href) {
    return (
      <Link href={href}>
        <a className={classes}>{text}</a>
      </Link>
    );
  } else {
    return <span className={classes}>{text}</span>;
  }
};
