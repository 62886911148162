import type { FC } from "react";
import Link from "next/link";

import type { NavLinkProps } from "./NavLink";

export const MobileNavLink: FC<NavLinkProps> = ({ href, text }) => {
  let classes = `
    block w-full p-5
    text-center text-white font-heading text-2xl tracking-wider
    active:opacity-50
  `;

  if (href) {
    return (
      <Link href={href}>
        <a className={classes}>{text}</a>
      </Link>
    );
  } else {
    return <span className={classes}>{text}</span>;
  }
};
